.xgplayer-skin-default .xgplayer-poster {
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.xgplayer-start, .xgplayer-nostart.xgplayer-pause .xgplayer-start{
    background: transparent !important;
}

.xgplayer-nostart.xgplayer-skin-default .xgplayer-start div svg{
    fill: transparent !important;
}

.xgplayer-pause .xgplayer-start{
    background: rgba(0, 0, 0, .38) !important;
}

